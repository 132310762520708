<template>
  <div class="offline-wx-error">
    <img src="~assets/images/user/unverified-icon.png" alt="">
    <p>请在微信客户端打开链接</p>
  </div>
</template>

<script>
export default {
  name: "WechatHint",
  created() {
    if(this.$handle.isWeiXin()){
      this.$router.replace('/')
    }
  }

}
</script>

<style scoped>
.offline-wx-error img {
  width: 1.70667rem;
  height: 1.70667rem;
  margin: 3.2rem auto 0.64rem;
  display: block; }
.offline-wx-error p {
  text-align: center;
  font-size: 0.68267rem; }
</style>